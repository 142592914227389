import { useMe } from '@/routes/_app/use-me';
import { S3Avatar } from './s3/user/s3-avatar';
import { useAuthInfo } from '@/routes/_app/use-auth-info';
import { Suspense } from 'react';
import { AvatarProps } from '@synoptic/ui-kit/avatar/avatar.js';

const MyAvatarComponent = (props: Omit<AvatarProps, 'imageSrc' | 'name'>) => {
  const me = useMe();

  return (
    <S3Avatar
      name={me?.fullName || ''}
      s3Key={me.avatar?.key}
      userId={me.id}
      {...props}
    />
  );
};

const MyAvatarFallback = (props: Omit<AvatarProps, 'imageSrc' | 'name'>) => {
  const { id, fullName } = useAuthInfo();

  return <S3Avatar name={fullName} s3Key={undefined} userId={id} {...props} />;
};

export const MyAvatar: React.FC<Omit<AvatarProps, 'imageSrc' | 'name'>> = (
  props,
) => {
  return (
    <Suspense fallback={<MyAvatarFallback {...props} />}>
      <MyAvatarComponent {...props} />
    </Suspense>
  );
};
